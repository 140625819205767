// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"tkvfaNU7keMbOetAKIJIh"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/reno";import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://f84a3f78a511f9f178c57e3d7670a238@o4505518331658240.ingest.sentry.io/4506637254918144',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: true,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    // We're on a static website that's free of personal identifiable or other
    // type of private data, so opt out of the default text masking and image
    // blocking by configuring the maskAllText and blockAllMedia configuration
    // https://docs.sentry.io/platforms/javascript/guides/nextjs/session-replay/privacy/#privacy-configuration
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});
